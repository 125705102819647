import Joi from 'joi';

const banks = {
	create: {
		name: Joi.string()
			.min(2)
			.required(),
		code: Joi.number().required(),
		swift: Joi.string().required(),
		address: Joi.string()
			.min(2)
			.required()
	}
};

export default banks;
