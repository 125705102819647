/* eslint-disable vue/no-duplicate-attributes */
<template>
	<div>
		<template v-if="loading">
			<div class="div__loading">
				<Loader />
			</div>
		</template>
		<template v-else>
			<section class="main-grid__row user-profile">
				<div class="user-profile__card">
					<div class="user-profile__section">
						<h5 class="user-profile__heading">Basic Information</h5>

						<div class="user-profile__item mt-5">
							<p class="user-profile__section__title">Name:</p>
							<p id="bankName">{{ itemData.name }}</p>
						</div>
						<div class="user-profile__item mt-5">
							<p class="user-profile__section__title">Code:</p>
							<p id="bankCode">{{ itemData.code }}</p>
						</div>
					</div>
				</div>
				<div class="user-profile__tabs">
					<ul class="nav nav-tabs" id="myTab" role="tablist">
						<li class="nav-item">
							<a
								class="nav-link active"
								id="profile-tab"
								data-toggle="tab"
								href="#profile"
								role="tab"
								aria-controls="profile"
								aria-selected="true"
							>
								Edit Detail
							</a>
						</li>
					</ul>
					<div class="tab-content" id="myTabContent">
						<div
							class="tab-pane fade show active user-profile__tabs__section"
							id="profile"
							role="tabpanel"
							aria-labelledby="profile-tab"
						>
							<div class="settings">
								<form method="post" @submit.prevent="update">
									<div class="settings__field">
										<div class="form-row">
											<div class="form-group col-md-12">
												<div class="settings__value">
													<label for="name">Name</label>
													<input
														:class="[{ 'is-invalid': errors.name }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.name"
														name="name"
														type="text"
														class="form-control"
														id="name"
													/>
												</div>
												<div class="invalid-feedback" id="fnameError">
													{{ errors.name }}
												</div>
											</div>
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="name">Code</label>
													<input
														:class="[{ 'is-invalid': errors.name }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.code"
														name="code"
														type="text"
														class="form-control"
														id="code"
													/>
												</div>
												<div class="invalid-feedback" id="fnameError">
													{{ errors.code }}
												</div>
											</div>
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="swift">Swift Code</label>
													<input
														:class="[{ 'is-invalid': errors.swift }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.swift"
														name="swift"
														type="text"
														class="form-control"
														id="swift"
													/>
												</div>
												<div class="invalid-feedback" id="swiftError">
													{{ errors.swift }}
												</div>
											</div>
											<div class="form-group col-md-12">
												<div class="settings__value">
													<label for="address">Address</label>
													<input
														:class="[{ 'is-invalid': errors.address }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.address"
														name="address"
														type="text"
														class="form-control"
														id="address"
													/>
												</div>
												<div class="invalid-feedback" id="addressError">
													{{ errors.address }}
												</div>
											</div>
										</div>
									</div>
									<div class="settings__primary">
										<button
											class="btn btn__save"
											type="button"
											name="button"
											@click.prevent="update"
											v-bind="{ disabled: loading }"
										>
											Save
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</template>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import form from '../../mixins/form';
import Loader from '../../components/Loader';
import bank from '../../utils/validations/banks';

export default {
	name: 'BankProfile',
	mixins: [form],
	data() {
		return {
			itemData: {},
			errors: {},
			loading: true
		};
	},
	created() {
		this.fetchBankProfile(this.$route.params.id);
	},
	watch: {
		getStatus(value) {
			if (value === 'success') {
				this.itemData = this.getBankProfile;
				this.loading = false;
			}
		}
	},
	computed: {
		...mapGetters(['getStatus', 'getBankUpdate', 'getBankProfile', 'getErrorLog']),
		isActive() {
			return [this.itemData.active === 1 ? 'badge badge-pill badge-success' : 'badge badge-pill badge-danger'];
		}
	},
	methods: {
		...mapActions(['updateBank', 'fetchBankProfile']),

		update() {
			const data = this.itemData;
			Object.keys(data).forEach((key) => {
				if (data[key] === null) {
					delete data[key];
				}
			});
			['is_deleted', 'updated_at', 'created_at', 'id'].forEach((item) => {
				delete data[item];
			});
			this.validate(data, bank.create);
			if (Object.keys(this.issues).length > 0) {
				this.errors = this.issues;
				return false;
			}
			const payload = { data, id: this.$route.params.id };
			this.updateBank(payload);
			return true;
		}
	},
	components: {
		Loader
	}
};
</script>

<style lang="scss" scoped>
.activity__item {
	display: grid;
	grid-template-columns: 120px 1fr;
	grid-gap: 25px;
	margin-bottom: 22px;
}
.settings {
	max-width: 90%;
	&__field {
		margin-top: 30px;
	}
	&__value {
		padding: 0;
		background: #fff;
		input {
			background: #fafafa;
			border: none;
			font-size: 14px;
			height: 36px;
		}
		label {
			font-weight: bold;
			margin-bottom: 0;
		}
	}
}

.avatar {
	&__input {
		display: none;
	}
	&__label {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
		margin: 0;
		&:hover {
			cursor: pointer;
		}
	}
	&__loader {
		position: absolute;
		top: 7px;
		right: -45px;
	}
}
h3 {
	padding-left: 6px;
}
.card {
	border-style: none;
}
.doc {
	&__wrapper {
		.text {
			margin-bottom: 15px;
		}
		margin-bottom: 50px;
	}
	&__img {
		width: 215px;
		height: 150px;
		object-fit: fill;
		object-position: center;
		//border: 1px solid $grey-light;
		border-radius: 4px;
		&--lg {
			width: 100%;
			height: auto;
		}
	}
}
.btn__wrapper {
	margin-top: 20px;
	.btn {
		margin: 4px;
		padding: 4px;
	}
}
.bold {
	font-weight: bold;
	font-size: 15px;
	margin-right: 5px;
}

.cards {
	padding-top: 2em;
	margin-right: 3em;
	&__row {
		display: flex;
		justify-content: space-between;
	}
	&__brand {
		font-weight: bold;
		padding-top: 1rem;
	}
	&__item {
		font-size: 1.5em;
		background: #f1f1f1;
		border: 1px solid #cccccc;
		border-radius: 6px;
		padding: 0.875em 1em;
		max-width: 350px;
		margin-bottom: 2.25rem;
		&:hover {
			.btn-sm {
				visibility: visible;
			}
		}
	}
}
</style>
